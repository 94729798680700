<template>
  <div class="teacher" :class="styles[isType]" v-show="isShow">
    <van-nav-bar
      class="teacher-header"
      :title="navTitle"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="teacher-body" v-if="isType === 0">
      <div class="teacher-photo">
        <img :src="headerImg" />
      </div>
      <div class="teacher-section">
        <div class="teacher-name">{{ name }}</div>
        <div class="teacher-title">{{ education }}</div>
        <div class="teacher-content" v-html="content"></div>
      </div>
    </div>
    <div class="teacher-body" v-if="isType === 1">
      <div class="teacher-photo-box">
        <div class="teacher-photo">
          <img :src="headerImg" />
        </div>
        <div class="teacher-name-box">
          <div class="teacher-name">{{ name }}</div>
          <div class="teacher-title">{{ education }}</div>
        </div>
      </div>
      <div class="teacher-section">
        <div class="teacher-content" v-html="content"></div>
      </div>
    </div>
    <div class="teacher-body" v-if="isType === 2">
      <div class="teacher-bg">
        <img :src="headerImg" />
      </div>
      <div class="teacher-section">
        <div class="teacher-section-header">
          <div class="teacher-name">{{ name }}</div>
          <div class="teacher-title">{{ education }}</div>
        </div>
        <div class="teacher-content" v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseUrl, detailType, teacherDetail } from "@/service";
export default {
  name: "teacher",
  data() {
    return {
      isHeader: false,
      styles: ["type1", "type2", "type3"],
      isType: 0,
      navTitle: "教师介绍",
      headerImg: "",
      title: "学习安排",
      name: "陈锋",
      education: "历史学博士",
      content: "",
      teacherCode: "",
      isShow: false,
    };
  },
  created() {
    let { teacherCode } = this.$route.query;
    this.teacherCode = teacherCode ? teacherCode : "";
    this.getType();
    this.getDetail();
  },
  methods: {
    async getType() {
      // 获取风格接口
      let res = await detailType(
        localStorage.getItem("SID"),
        "sys_teacher_introduce"
      );
      this.isType = parseInt(res[0].dictValue);
    },
    async getDetail() {
      // 获取详情接口
      let { data } = await teacherDetail(
        localStorage.getItem("SID"),
        this.teacherCode
      );
      if (data) {
        this.education = data.education;
        this.name = data.userName;
        this.content = data.introduce;
        this.headerImg = baseUrl + data.avatarUrl;
        setTimeout(() => {
          this.isShow = true;
        }, 100);
      }
    },
    onClickLeft() {
      this.$router.push({
        path: "/plan",
      });
    },
  },
};
</script>

<style lang="less">
.teacher {
  font-family: PingFangSC-Semibold, PingFang SC;
  min-height: 100vh;
  &.type1 {
    background: rgba(0, 110, 199, 1);
    .teacher-photo {
      width: 110px;
      height: 110px;
      border-radius: 55px;
      background: rgba(0, 110, 199, 0.13);
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transform: translate(-6px, -6px);
      }
    }
  }
  &.type2 {
    background: #f2f2f2;
    .teacher-body {
      padding: 20px;
      .teacher-section {
        padding-top: 24px;
        .teacher-content {
          padding: 16px;
          color: #005192;
          font-size: 17px;
          background: #e2f2ff;
        }
      }
    }
    .teacher-photo-box {
      display: flex;
      padding-bottom: 24px;
      border-bottom: 2px solid #006ec7;
      .teacher-photo {
        width: 110px;
        height: 110px;
        border-radius: 55px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
    .teacher-name-box {
      flex: 1;
      padding-left: 41px;
      .teacher-name {
        font-size: 22px;
        color: #3f3f3f;
      }
      .teacher-title {
        font-size: 17px;
        color: #3f3f3f;
      }
    }
  }
  &.type3 {
    background: #f2f2f2;
    .teacher-body {
      padding: 0;
      margin: 0;
      border-radius: 0;
      min-height: calc(100vh - 46px);
    }
    .teacher-bg {
      height: 87px;
      background: url(../static/teacher3.png) 0 0 no-repeat;
      background-size: cover;
      position: relative;
      margin-bottom: 55px;
      img {
        width: 110px;
        height: 110px;
        border-radius: 55px;
        position: absolute;
        bottom: -55px;
        left: 35px;
      }
    }
    .teacher-section {
      padding: 0 35px;
      .teacher-section-header {
        overflow: hidden;
        padding: 20px 0;
        .teacher-name {
          float: left;
          padding: 0;
        }
        .teacher-title {
          float: right;
          padding: 0;
          padding-top: 6px;
        }
      }
    }
  }
  .header-bg {
    width: 100vw;
    height: 166px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .header-mask {
      width: 100%;
      height: 167px;
      position: absolute;
      top: 0;
    }
  }
  .teacher-body {
    background: #ffffff;
    border-radius: 12px;
    margin: 20px 15px 20px;
    padding: 26px 18px 26px;
    position: relative;
    &.no-header {
      margin: -68px 15px 20px;
    }
    .teacher-section {
      .teacher-name {
        font-size: 22px;
        font-weight: 500;
        color: #3f3f3f;
        text-align: center;
        padding: 12px 0;
      }
      .teacher-title {
        font-size: 17px;
        color: #3f3f3f;
        text-align: center;
        padding-bottom: 14px;
      }
      .teacher-content {
        font-size: 17px;
        color: #3f3f3f;
        line-height: 30px;
      }
    }
  }
}
</style>
